import { AbstractControl, ValidatorFn } from "@angular/forms";

import { VALIDATION_REG_EXP } from "../constants/validators/validation-reg-exp";

export default function email(
  msg?: string,
  internalEmail: boolean = false,
): ValidatorFn {
  return (control: AbstractControl) => {
    const message: string = msg || "Email is not valid";

    return validate(control, internalEmail) ? null : { email: message };
  };
}

function validate(control: AbstractControl, internalEmail: boolean): boolean {
  return (
    VALIDATION_REG_EXP.email.test(control.value) &&
    (internalEmail ? true : !control.value.includes("@dnb."))
  );
}
